.tech table {
  border-collapse: separate;
  border-spacing: 0px;
  border: 1px solid;
  padding: 0px;
  margin: 10px;
  display: inline-block;
  background-color: #0f3455;
  color: white;
  /* font-size: 18px; */
}

td,
th {
  border: 1px solid rgb(90, 83, 83);
  padding: 5px;
  /* text-align: center; */
}

ul {
  list-style: disc;
  margin: 0px 0px 0px 20px;
  padding: 0px;
  text-align: left;
  display: inline-block;
}

.tech li {
  padding: 10px;
}

ol {
  /* background-color: #920f0f; */
  /* padding: 10px; */
  /* margin: 1px; */
  list-style: decimal;
  /* display:flexbox; */
  /* align-items:baseline; */
  /* gap: 3rem; */
  display: inline-block;
  /* list-style-position:inside; */
  text-align: left;
}
